body {
  background-color: #1e2125;
}

.App {
  text-align: center;
  background-color: #1e2125;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.forms {
  display: flex;
  flex-direction: row;
}

.container {
  width: 34vw;
  height: 85vh;
  margin: 1vw;
  margin-right: .5vw;
  margin-left: .5vw;
  background-color: #25282c;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  color: white;
}

.preset-container {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
}

.grid-container {
  border: 1px solid #555;
  background-color: #25282c;
  border-radius: 8px;
  color: white;
}

.preset-container > div {
  flex: 1;
}

.container:first-child {
  margin-left: 1vw;
}

.container:last-child {
  margin-right: 1vw;
}
